var journeum = {
  show_location_map_popup: function (destination_coordinates) {
    var icon = new google.maps.MarkerImage(
      "static/img/markers/pin-2.png",
      new google.maps.Size(16.0, 27.0),
      new google.maps.Point(0.0, 0.0),
      new google.maps.Point(8.0, 13.0)
    );
    var shadow = new google.maps.MarkerImage(
      "static/img/markers/pin-2-shadow.png",
      new google.maps.Size(30.0, 27.0),
      new google.maps.Point(0.0, 0.0),
      new google.maps.Point(8.0, 13.0)
    );

    var dst_loc = new google.maps.LatLng(
      destination_coordinates[0],
      destination_coordinates[1]
    );
    var options = {
      zoom: 13,
      center: dst_loc,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    var map = new google.maps.Map(
      document.getElementById("jm-overlay-map"),
      options
    );

    var marker = new google.maps.Marker({
      position: dst_loc,
      map: map,
      icon: icon,
      shadow: shadow,
    });
  },

  setup_overview: function (destination_coordinates, viewport, topDst) {
    /*
    function setupMap() {
      var icon = new google.maps.MarkerImage("https://www.journeum.com/static/img/markers/pin-2.png",
        new google.maps.Size(16.0, 27.0),
        new google.maps.Point(0.0, 0.0),
        new google.maps.Point(8.0, 13.0)
      );
      var shadow = new google.maps.MarkerImage("https://www.journeum.com/static/img/markers/pin-2-shadow.png",
        new google.maps.Size(30.0, 27.0),
        new google.maps.Point(0.0, 0.0),
        new google.maps.Point(8.0, 13.0)
      );

      var dst_loc = new google.maps.LatLng(destination_coordinates[0],destination_coordinates[1]);
      var options = {
        zoom: 8,
        center: dst_loc,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      var map = new google.maps.Map(document.getElementById("destination_map"),options);

      if (viewport && viewport.length === 4) {
        var vp = new google.maps.LatLngBounds(new google.maps.LatLng(viewport[0],viewport[1]), new google.maps.LatLng(viewport[2],viewport[3]));
        map.fitBounds(vp);
      }

      var marker = new google.maps.Marker({
        position: dst_loc,
        map: map,
        icon: icon,
        shadow: shadow,
      });
      if (topDst) {
        journeum.setup_top_destinations(map, topDst);
      }
      */

    // var nearby_markers = [];
    // var nearby_icon = new google.maps.MarkerImage("www.journeum.com/static/img/markers/pin-2-dsb.png",
    //   new google.maps.Size(16.0, 27.0),
    //   new google.maps.Point(0.0, 0.0),
    //   new google.maps.Point(8.0, 13.0)
    // );
    //
    // if (places_nearby) {
    //   jQuery.each(places_nearby, function(i, place) {
    //     if (place.location[0] == destination_coordinates[0] && place.location[1] == destination_coordinates[1]) {
    //       // just to show points at slightly different place
    //       var loc = new google.maps.LatLng(place.location[0] + (Math.random()-0.2)*0.4, place.location[1] + (Math.random()-0.2)*0.4);
    //     } else {
    //       var loc = new google.maps.LatLng(place.location[0],place.location[1]);
    //     }
    //     var onclick = journeum._create_clickable_marker({
    //       'map' : map,
    //       'latlng' : loc,
    //       'txt' : journeum._get_marker_cloud_text([place]),
    //       'markers' : nearby_markers,
    //       'icon'  : nearby_icon,
    //       'shadow'  : shadow,
    //     });
    //   });
    // }
    // }

    if ($("#google-maps-script").length > 0) {
      return;
    }

    $.ajaxSetup({
      cache: true,
    });

    // $.getScript('//maps.google.com/maps/api/js',function(){
    //   setupMap();
    // });

    // s = $('<script>');
    // s.attr('id', 'google-maps-script');
    // s.attr('src','//maps.google.com/maps/api/js');
    // s.attr('async', true);
    // s.on('load', function() {
    //   setupMap();
    // });
    // $('body').append(s);

    $(".overview-photos a").each(function (idx, elem) {
      if (
        $(elem).attr("id") &&
        $(elem)
          .attr("id")
          .match(/^overview-photo-thumbnail/)
      ) {
        var href = "#" + $(elem).attr("id").replace("-thumbnail", "");
        $(elem).colorbox({ transition: "fade", inline: true, href: href });
      }
    });
  },

  setup_top_destinations: function (map, topDst) {
    var markers = [];

    var shadow = new google.maps.MarkerImage(
      "/static/img/markers/shadow-loc.png",
      new google.maps.Size(31.0, 20.0),
      new google.maps.Point(0, 0),
      new google.maps.Point(10.0, 10.0)
    );

    var iconidx = 0;
    jQuery.each(topDst, function (idx, entry) {
      var iconx = (iconidx % 5) * 20;
      var icony = Math.floor(iconidx / 5) * 20;

      // if (entry.lat == data.destination.lat && entry.lng == data.destination.lng) {
      // 	// put ? icon coordinates here
      // 	iconx = 80;
      // 	icony = 80;
      // } else {
      iconidx++;
      // }

      var icon = new google.maps.MarkerImage(
        "/static/img/markers/loc-sprite.png",
        new google.maps.Size(20.0, 20.0),
        new google.maps.Point(iconx, icony),
        new google.maps.Point(10.0, 10.0)
      );

      var onclick = journeum._create_clickable_marker({
        icon: icon,
        shadow: shadow,
        map: map,
        latlng: new google.maps.LatLng(entry.location[0], entry.location[1]),
        txt: journeum._get_marker_cloud_text([entry]),
        markers: markers,
      });

      $("#destinations_list span i")
        .eq(idx)
        .addClass("loc-icon")
        .css({
          background:
            'url("/static/img/markers/loc-sprite.png") -' +
            iconx +
            "px -" +
            icony +
            "px",
        })
        .click(onclick);
    });

    // if (data.bounds) {
    // 	var bounds = data.bounds;
    // 	var southWest = new google.maps.LatLng(bounds[0],bounds[1]);
    // 	var northEast = new google.maps.LatLng(bounds[2],bounds[3]);
    // 	var bounds = new google.maps.LatLngBounds(southWest,northEast);
    // 	map.fitBounds(bounds);
    // }
  },
  _get_marker_cloud_text: function (destinations) {
    var txt = '<div class="marker-cloud">';
    jQuery.each(destinations, function (i, dst) {
      txt +=
        '<a class="title" title="' +
        dst.name +
        '" href="/dst/' +
        dst.url +
        '">' +
        dst.name +
        '</a><p class="description">' +
        dst.description +
        "</p>";
    });
    txt += "</div>";
    return txt;
  },

  _create_clickable_marker: function (options) {
    /*
    {
      'icon' : icon_obj,
      'shadow' : shadow_obj,
      'map' : map,
      'latlng' : obj,
      'txt' : text,
      'onclick' function,
    }
  */
    var marker = new google.maps.Marker({
      map: options.map,
      draggable: false,
      icon: options.icon,
      shadow: options.shadow,
      position: options.latlng,
    });
    marker.infowindow = new google.maps.InfoWindow({
      content: options.txt,
      maxWidth: 300,
    });
    var onclick = function () {
      jQuery.each(options.markers, function (i, marker) {
        marker.infowindow.close();
      });
      marker.infowindow.open(options.map, marker);
    };
    google.maps.event.addListener(marker, "click", onclick);
    options.markers.push(marker);
    return onclick;
  },

  get_marker_image_for_number: function (number) {
    var iconx = null;
    var icony = null;

    if (number > 0) {
      iconx = (number % 5) * 20;
      icony = Math.floor(number / 5) * 20;
    } else {
      // '?' icon coordinates
      iconx = 80;
      icony = 80;
    }

    return new google.maps.MarkerImage(
      "/static/img/markers/loc-sprite.png",
      new google.maps.Size(20.0, 20.0),
      new google.maps.Point(iconx, icony),
      new google.maps.Point(10.0, 10.0)
    );
  },
  set_google_ad: function (el) {
    if (!el) return;

    var e = $(el);
    var adWidth = e.width();
    // e.css('width', adWidth);
    // e.css('display', 'block');
    // console.log('w', adWidth);

    if (e.find(".adsbygoogle").length > 0) return;

    var ins = $("<ins>");
    ins.attr("class", "adsbygoogle");
    ins.css("display", "inline-block");
    ins.css("width", 728);
    ins.css("height", 90);
    ins.attr("data-ad-client", "ca-pub-3367104205023194");
    ins.attr("data-ad-slot", "1081885469");

    // (adsbygoogle = window.adsbygoogle || []).push({});
  },
};

var searchTimeout;

window.$(document).ready(function () {
  // if (window.ga == null) window.ga = function () {};

  var overviewData = $("#jm-overview-data");

  if (overviewData.length > 0) {
    var location = overviewData.data("location");
    var bbox = overviewData.data("bbox");
    var topdst = overviewData.data("topdst");

    journeum.setup_overview(location, bbox, topdst);
  }

  var hasScrolled = false;
  // var adsenseLoaded = false;

  var envEl = document.querySelector('[name="env"]');
  var env =
    envEl == null
      ? "production"
      : envEl.attributes.content.value || "production";
  var isDev = env !== "production";
  // console.log("env", env, isDev);

  // adsenseLoaded = true;
  // if (!isDev) {
  //   var s = $("<script>");
  //   s.attr("id", "adsense-script");
  //   s.attr("src", "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js");
  //   s.attr("async", true);
  //   $("body").append(s);
  // }

  journeum.set_google_ad(document.getElementById(".google-ad"));

  var sentScrollEvent = false;
  $(window).scroll(function () {
    if (sentScrollEvent) return;
    sentScrollEvent = true;
    var o = {
      hitType: "event",
      eventCategory: "user-action",
      eventAction: "scroll",
      eventLabel: "window",
    };
    // console.log('SCROLL', o);
    if (window.ga) ga("send", o);
  });

  $(document).on("mdl-componentupgraded", function (x, y) {
    if (!hasScrolled) {
      hasScrolled = true;

      var rightScroll = document.querySelector(
        ".scrollindicator.scrollindicator--right"
      );
      var leftScroll = document.querySelector(
        ".scrollindicator.scrollindicator--left"
      );
      var menuBar = document.querySelector(".docs-navigation");
      var delta = 40;

      function updateScrollIndicator() {
        leftScroll.classList.remove("disabled");
        rightScroll.classList.remove("disabled");
        if (menuBar.scrollLeft <= 0) {
          leftScroll.classList.add("disabled");
        }
        // 5px tolerance because browsers!
        if (
          menuBar.scrollLeft + menuBar.clientWidth + 5 >=
          menuBar.scrollWidth
        ) {
          rightScroll.classList.add("disabled");
        }
      }
      if (menuBar) {
        menuBar.addEventListener("scroll", updateScrollIndicator);
        updateScrollIndicator();

        function scrollMenuBar(delta) {
          menuBar.scrollLeft += delta;
        }

        rightScroll.addEventListener("click", scrollMenuBar.bind(null, delta));
        rightScroll.addEventListener("tap", scrollMenuBar.bind(null, delta));
        leftScroll.addEventListener("click", scrollMenuBar.bind(null, -delta));
        leftScroll.addEventListener("tap", scrollMenuBar.bind(null, -delta));
      }

      var tabBar = $(".mdl-navigation");

      if (tabBar.length === 0) {
        return;
      }

      var selectedTab = tabBar.find(".is-active");
      var rightOfTab = selectedTab.position().left + selectedTab.width() + 64;

      if (rightOfTab > $(window).width()) {
        var ss =
          selectedTab.position().left +
          selectedTab.width() -
          $(window).width() +
          64;
        tabBar.animate({ scrollLeft: ss }, 200);
        hasScrolled = true;
      }
    }

    // console.log('Upgraded!', x, y);
  });

  $(".jm-noteimg").each(function (i, img) {
    img = $(img);
    var c = img.parent().parent();
    var w = c.width();
    var h = c.height();
    var url = img.data("jm-src");
    url = url.replace("JOURNEUMX", w).replace("JOURNEUMY", h);
    $.get("/staticmapurl?url=" + encodeURIComponent(url), function (x) {
      img.attr("src", x);
    });
    // console.log('img', img, w, h, url);
  });

  $(".jm-mapzoom").on("click", function (e) {
    e.preventDefault();
    var ccc = $(this).data("jmCoordinates");
    // console.log('CLICK', $(this).data('jmCoordinates'));
    $.getScript("//maps.google.com/maps/api/js", function () {
      journeum.show_location_map_popup(ccc);
      $(".jm-overlay-map").css("display", "block");
      (adsbygoogle = window.adsbygoogle || []).push({});
    });

    var o = {
      hitType: "event",
      eventCategory: "location-map-open",
      eventAction: $(this).data("jmLocation"),
      eventLabel: $(this).data("jmClass"),
      eventValue: $(this).data("jmTitle"),
    };
    // console.log('Sending to GA: ', o);
    if (window.ga) ga("send", o);
  });

  $(".jm-close").on("click", function () {
    $(".jm-overlay-map").css("display", "none");
  });

  $("#search-field").on("blur", function (e) {
    setTimeout(function () {
      var l = $("#search-results");
      l.html("");
      l.parent().css("display", "none");
    }, 500);

    if ($(document).width() < 900) {
      $(".main-header").show();
    }
  });

  $("#search-field").on("focus", function (e) {
    if ($(document).width() < 900) {
      $(".main-header").hide();
    }
  });

  function search() {
    var term = $(this).val();
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(function () {
      $.get("/jsearch?term=" + encodeURIComponent(term), function (x) {
        var l = $("#search-results");
        l.html("");
        l.parent().css("display", x.length > 0 ? "block" : "none");

        x.forEach(function (r) {
          var i = $("<li>");
          var link = $("<a>")
            .html(r.name)
            .attr("href", "/dst/" + r.path);
          i.append(link);
          l.append(i);
        });
      });
    }, 500);
  }

  $("#search-field").on("keyup", search);
  $("#search-field").on("focus", search);
});

window.journeum = journeum;
