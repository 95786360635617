const jQuery = require("./javascripts/_jquery");
window.$ = jQuery;
window.jQuery = jQuery;

$(() => {
  require("./javascripts/material");
  require("./javascripts/journeum");
});

import "./stylesheets/_material.css";
import "./stylesheets/main.css";
